.reviewOrder {
    composes: reviewOrder from './index.vars.css';
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid var(--col--gray4);
    padding-top: var(--reviewOrder-padding-top);
}

.reviewOrderItem {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.greenColor {
    color: var(--col--secondary5);
}

.price {
    text-align: right;
}

.totalItem {
    padding-bottom: var(--totalItem-padding);
    vertical-align: middle;
    color: var(--col--dark);
}

.discount {
    color: var(--discount-color);
}

.subtotalContainer {
    position: relative;
    max-width: fit-content;
}

.reviewOrderItem:last-child .totalLine {
    padding-bottom: 0;
}

.cardNumber {
    padding-bottom: 0;
}

.tooltip {
    width: var(--reviewOrder-tooltip-width, 314px);
    left: var(--reviewOrder-tooltip-left, -15px);
    bottom: 3px;
}

.tooltipLabel {
    font-size: 14px;
    line-height: 18px;
}

@media (max-width: 600px) {
    .tooltip {
        max-width: 282px;
    }
}

.legal {
    color: var(--legal-color);
}

.discountFont {
    font-family: var(--totalItem-discount-font);
}

.discountMinus::after {
    content: var(--reviewOrder-dicount-minus-after-content, '');
}
