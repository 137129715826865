.inputGroup {
    width: 100%;
    font-size: 16px;
    margin-bottom: 1em;
    position: relative;
}

.inputLabel {
    color: var(--col--gray6);
    display: inline-block;
    font-family: Arial, sens-serif;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}

.inputControl {
    border: solid 1px var(--col--gray6);
    border-radius: 0;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
    font-size: 16px;
    padding-left: 20px;
}

.inputControl:focus {
    box-shadow: none;
    border: solid 1px var(--col--gray6);
    border-radius: 0;
    outline: none;
}

.valid .inputControl:not(:disabled) {
    border: solid 1px var(--col--gray6);
}

.valid .inputControl:not(:disabled):focus {
    border: solid 1px var(--col--gray6);
    box-shadow: none;
    outline: none;
}

.inputError {
    color: var(--col--primary1);
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 80%;
    margin-top: 0.31em;
}

.dropdownItem {
    height: 50px;
    font-family: var(--item-font-family);
    font-size: 16px;
    line-height: 24px;
    color: var(--col--dark);
}

.dropdownItem::before {
    display: none;
}

.dropdownError {
    border: 1px solid var(--col--primary1);
}

:global(.MuiMenuItem-root).selectedDropdownItem {
    font-family: var(--selectedDropdownItem-font-family);
    background-color: transparent;
}

.error {
    color: var(--col--primary1);
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 80%;
    margin-top: 0.31em;
}

.labelHint {
    color: var(--col--primary1);
}

.inputGroup p {
    overflow: hidden;
    text-overflow: ellipsis;
}

.readOnlyInput {
    height: 50px;
    border: 1px solid var(--col--gray6);
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    padding: 13px 20px;
    box-sizing: border-box;
    color: var(--col--dark);
    font-size: 16px;
    padding-left: 20px;
    width: 100%;
}

.readOnlyInputError {
    border: 1px solid var(--col--utilityError);
    border-radius: 2px;
}

.readOnlyInput:focus {
    outline-width: 0;
}

.asterisk {
    color: var(--col--utilityError);
}

.optionsListClassName {
    max-height: 350px;
}

@media (min-width: 481px) and (max-width: 959px) {
    .optionsListClassName {
        max-height: 300px;
    }
}
