.wrapper {
    position: relative;
    display: flex;
    height: 45px;
    max-height: 45px;
    min-height: 45px;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    box-sizing: border-box;
    border-top: var(--actions-border-top);
    padding: var(--actions-padding);
    background: var(--actions-background);
    border-bottom-left-radius: var(--card-border-radius);
    border-bottom-right-radius: var(--card-border-radius);
}

/* TODO get rid of this by adding proper styles to Arbys InspireLink */
.productItemButton {
    color: var(--btn-font-color);
    font-size: var(--productItemButton-font-size);
    font-family: var(--productItemButton-font-family);
    line-height: var(--productItemButton-line-height);
    font-weight: var(--productItemButton-font-weight);
}
