.caloriesContainer {
    flex: auto;
    padding: 40px 0;
}

.caloriesContainer :global(.t-link) {
    text-decoration-line: underline;
}

.caloriesParagraph {
    composes: t-paragraph-small from global;
    color: var(--col--dark);
    padding-bottom: 25px;
}

@media (max-width: 767px) {
    .caloriesContainer {
        flex: auto;
    }

    .caloriesParagraph {
        padding-bottom: 15px;
    }
}
