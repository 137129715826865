.productsContainer {
    composes: recommendedItems from './index.vars.css';
    margin-top: var(--recommendedItems-productsContainer-mobile-margin-top);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 300px;
}

.productsHeading {
    composes: t-header-card-title from global;
    margin-bottom: 10px;
}

.rightArrow,
.leftArrow {
    width: 32px;
    height: 32px;
    top: 28px;
    font-size: 1.5rem !important;
}

.rightArrow {
    right: 0 !important;
}

.leftArrow {
    left: 10px !important;
}

.productsHeader {
    margin-top: 5px;
    margin-bottom: 10px;
}

.scrollerlist {
    padding-top: 25px;
    padding-left: 25px;
}

.scrollerContainer {
    margin-left: -25px;
}

@media screen and (min-width: 960px) {
    .productsHeader {
        font-size: 20px;
    }

    .productsContainer {
        margin-top: var(--recommendedItems-productsContainer-margin-top);
        min-height: 400px;
    }
}
