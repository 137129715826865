.container {
    --container-padding: 15px 20px 20px;
    --offerName-line-height: 22px;
    --container-font-weight: 400;
    --container-border-bottom-left-radius: 0;
    --container-border-bottom-right-radius: 0;
    --selectProductsContainer-border: 1px dashed var(--col--gray4);
    --selectProductsContainer-color: var(--col--secondary4);
    --container-mobile-padding: 15px 20px 20px;
    --offerName-color: var(--col--dark);
}
