.container {
    composes: container from './bagItemSuggestedDealTop.vars.css';
    background: var(--col--gray1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: var(--container-padding);
    border: 1px solid var(--col--gray4);
    text-align: center;
    height: var(--container-height);
    box-sizing: border-box;
    border-top-left-radius: var(--container-border-top-left-radius);
    border-top-right-radius: var(--container-border-top-right-radius);
}

.applyDealButton {
    color: var(--col--primary1);
    text-decoration: underline;
    cursor: pointer;
    white-space: nowrap;
}

.dealName {
    color: var(--dealName-color);
}

@media (max-width: 960px) {
    .container {
        padding: var(--container-mobile-padding);
        font-size: var(--container-mobile-font-size);
        line-height: var(--container-mobile-line-height);
    }
}
