.verticalProductCard {
    --card-desktop-width: 205px;
    --card-desktop-min-height: 295px;
    --card-mobile-width: 158px;
    --card-mobile-min-height: 240px;
    --card-mobile-height: 240px;
    --card-less-mobile-width: 145px; /* max-width: 374px */
    --card-align-self: flex-start;
    --card-desktop-margin: 0 20px 20px 0;
    --card-mobile-margin: 0 19px 20px 0;
    --card-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    --card-border: none;
    --card-border-radius: 0px;
    --card-background: var(--col--light);
    --image-mobile-padding: 10px;
    --image-desktop-padding: 10px;
    --image-mobile-min-height: 125px;
    --image-desktop-min-height: 158px;
    --card-item-description-height: 92px;
    --card-item-description-mobile-height: 75px;
    --product-name-margin: 0;
    --actions-background: transparent;
    --actions-border-top: 1px solid var(--col--gray3);
    --actions-padding: 0 10px;
    --btn-font-color: var(--col--dark);
    --itemName-font-size: 20px;
    --itemName-line-height: 22px;
    --itemName-text-transform: capitalize;
    --productItemButton-font-size: 11px;
    --productItemButton-line-height: 11px;
    --productItemButton-font-family: var(--ff--button);
    --productItemButton-font-weight: 800;
    --badgeVerticalProductCard-border-radius: 0;
}

@media (max-width: 767px) {
    .verticalProductCard {
        --itemName-font-size: 16px;
        --itemName-line-height: 18px;
    }
}
