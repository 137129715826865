.modal {
    --header-margin-bottom: 0;
    --subtitle-font-size: 20px;
    --subtitle-line-height: 22px;
    --subtitle-font-family: var(--ff--header);
    --subtitle-font-weight: 700;
    --title-font-weight: 900;
    --content-padding: 60px 40px 0;
    --footer-padding: 30px 40px 40px;
    --container-after-height: 5px;
    --footer-mobile-padding: 0;
    --footerButton-mobile-height: 60px;
    --headerTop-mobile-margin-bottom: 10px;
    --subtitle-mobile-font-size: 16px;
    --subtitle-mobile-line-height: 18px;
    --title-mobile-font-size: 34px;
    --content-mobile-padding: 23px 20px 40px;
    --container-border-radius: 0;
    --contentContainer-gap: unset;
    --content-large-padding-right: 40px;
}
