.container {
    composes: container from './tooltip.vars.css';
    opacity: 1;
    padding: 6px 16px;
    border-radius: 0;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.container.light {
    background-color: var(--col--light);
    color: var(--col--dark);
    border: 1px solid var(--col--gray5);
}

.container.dark {
    background-color: var(--col--dark);
    color: var(--col--light);
}

.container.primary {
    background-color: var(--container-primary-background-color);
    color: var(--col--light);
    padding: 12px 16px;
    border-radius: var(--container-primary-border-radius);
}

:global(.MuiTooltip-popperArrow[x-placement*='bottom']) .arrow::before,
:global(.MuiTooltip-popperArrow[x-placement*='top']) .arrow::before,
:global(.MuiTooltip-popperArrow[x-placement*='left']) .arrow::before,
:global(.MuiTooltip-popperArrow[x-placement*='right']) .arrow::before {
    content: '';
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
}

:global(.MuiTooltip-popperArrow[x-placement*='bottom']) .lightArrow::before,
:global(.MuiTooltip-popperArrow[x-placement*='top']) .lightArrow::before,
:global(.MuiTooltip-popperArrow[x-placement*='left']) .lightArrow::before,
:global(.MuiTooltip-popperArrow[x-placement*='right']) .lightArrow::before {
    background-color: var(--col--light);
    border-color: var(--col--gray5);
    border-width: 1px;
}

:global(.MuiTooltip-popperArrow[x-placement*='bottom']) .darkArrow::before,
:global(.MuiTooltip-popperArrow[x-placement*='top']) .darkArrow::before,
:global(.MuiTooltip-popperArrow[x-placement*='left']) .darkArrow::before,
:global(.MuiTooltip-popperArrow[x-placement*='right']) .darkArrow::before {
    background-color: var(--col--dark);
    border: none;
}

:global(.MuiTooltip-popperArrow[x-placement*='bottom']) .primaryArrow::before,
:global(.MuiTooltip-popperArrow[x-placement*='top']) .primaryArrow::before,
:global(.MuiTooltip-popperArrow[x-placement*='left']) .primaryArrow::before,
:global(.MuiTooltip-popperArrow[x-placement*='right']) .primaryArrow::before {
    background-color: var(--container-primary-background-color);
    border: none;
}

/*** BOTTOM ARROW ***/

:global(.MuiTooltip-popperArrow[x-placement*='bottom']) .arrow {
    top: -10px;
    margin: 0;
    width: 20px;
    height: 10px;
    overflow: hidden;
}

:global(.MuiTooltip-popperArrow[x-placement*='bottom']) .arrow::before {
    transform-origin: 3px 8px;
    width: 14px;
    height: 14px;
    box-sizing: border-box;
}

/***** TOP ARROW ****/

:global(.MuiTooltip-popperArrow[x-placement*='top']) .arrow {
    width: 20px;
    height: 10px;
    margin: 0;
    bottom: -10px;
    overflow: hidden;
}

:global(.MuiTooltip-popperArrow[x-placement*='top']) .arrow::before {
    transform-origin: 16px 4px;
    width: 14px;
    height: 14px;
    box-sizing: border-box;
}

/***** LEFT ARROW ****/

:global(.MuiTooltip-popperArrow[x-placement*='left']) .arrow {
    right: -10px;
    width: 10px;
    height: 20px;
    margin: -2px 0 0;
    overflow: hidden;
}

:global(.MuiTooltip-popperArrow[x-placement*='left']) .arrow::before {
    transform-origin: 0 0;
    width: 14px;
    height: 14px;
    box-sizing: border-box;
}

/***** RIGHT ARROW ****/

:global(.MuiTooltip-popperArrow[x-placement*='right']) .arrow {
    left: -10px;
    width: 10px;
    height: 20px;
    margin: 0;
    overflow: hidden;
}

:global(.MuiTooltip-popperArrow[x-placement*='right']) .arrow::before {
    transform-origin: 5px 12px;
    width: 14px;
    height: 14px;
    box-sizing: border-box;
}
