.locationMethodTab {
    --details-container-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
    --details-container-background-color: var(--col--light);
    --details-container-border: none;
    --details-container-border-radius: 0;
    --details-container-padding: 0 30px;
    --background-line-background-color: transparent;
    --title-color: var(--col--primary1);
    --mobile-title-text-transform: capitalise;
    --mobile-withLink-font-size: 18px;
    --mobile-changeLocationButton-font-size: 11px;
    --mobile-changeLocationButton-line-height: 11px;
    --mobile-changeLocationButton-font-weight: 800;
}
