.container {
    height: 100vh;
    display: flex;
    position: absolute;
}

.modal {
    width: 100%;
    height: 100%;
    display: flex;
}

.loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--col--light);
}

.content:focus {
    outline: none;
}

.closeButton {
    composes: bag from './index.vars.css';
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    text-transform: var(--bag-close-text-transform, uppercase);
    color: var(--bag-close-text-color, var(--col--light));
    background: var(--bag-close-background, var(--col--dark));
    border-radius: 0;
    height: var(--bag-close-batton-height, 66px);
    border-width: 0;
    font-size: var(--bag-close-label-font-size);
    cursor: pointer;
}

.closeButtonIcon::before {
    color: var(--bag-close-text-color, var(--col--light));
}

.closeButton > * {
    margin-right: 13px;
}

.divider {
    border-top: 1px solid var(--col--gray5);
    background-color: var(--col--gray1);
    margin: 0 25px;
    opacity: 0.25;
}

.bag {
    composes: bag from './index.vars.css';
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    flex-direction: column;
}

.bag > * {
    margin: 0 20px;
}

.bagEmpty > * {
    margin: 0 20px;
}

.bagEmpty {
    width: 100%;
}

.bagItems {
    margin: 0;
    padding: var(--bagItems-padding);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
}

.bagItemsEmpty {
    height: calc(100vh - 215px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    max-width: var(--bag-item-empty-max-width);
    padding: var(--bag-item-empty-padding-mobile);
    box-sizing: var(--bag-item-empty-box-sizing);
    overflow-y: auto;
}

.marginBottomBagItems {
    height: calc(100vh - 336px - 110px);
    margin-bottom: 110px;
}

.headerContainer {
    padding: var(--headerContainer-padding);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--header-container-border-color, var(--col--gray5));
    min-height: min-content;
}

.header {
    height: var(--header-height);
    margin: 0;
    float: left;
    align-self: flex-end;
    text-transform: var(--header-text-transform);
}

.itemCount {
    float: right;
    font-size: 24px;
    line-height: 24px;
    font-family: var(--subhead-font);
    margin-left: 10px;
    align-self: flex-end;
    margin-bottom: 4px;
}

.yourBagHeaderContainer {
    display: flex;
}

.total {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.totalHint {
    color: var(--col--dark);
}

.summaryHeader {
    margin: var(--summaryHeader-margin);
}

.buttons {
    width: var(--buttons-width, 100%);
    display: flex;
    height: var(--buttons-height-mobile, 60px);
    padding: var(--buttons-padding-mobile, 0);
    box-shadow: var(--buttons-box-shadow, none);
}

.buttons a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttons a > * {
    flex-grow: 1;
}

.buttons > * {
    flex-grow: 1;
}

.buttons > *:nth-child(1) {
    margin-right: 1px;
}

.buttons.callToActionButtons > *:first-child {
    margin-right: 0;
}

.buttons .bagButton {
    flex: 1;
    padding: 0;
}

.tooltip {
    bottom: 20px;
    height: auto;
    min-height: auto;
}

.reviewTotal {
    padding: var(--review-total-padding, 0 0 20px);
    margin-top: var(--review-total-margin-top, 20px);
    display: -webkit-box;
    align-items: flex-end;
    -webkit-box-pack: end;
    -webkit-box-align: end;
}

.unavailableHeader {
    margin-bottom: 10px;
}

.unavailableRemove {
    text-transform: var(--unavailableRemove-text-transform, uppercase);
    background: transparent;
    border: none;
}

.unavailableHint {
    margin-bottom: 10px;
    color: var(--unavailableHint-color);
    display: flex;
    width: 100%;
}

.unavailableNotification {
    width: 100%;
    padding: 17px 0;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--col--light);
    background-color: var(--col--utilityError);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
}

.phoneLink {
    pointer-events: auto;
}

.locationSelectWrapper {
    composes: bag from './index.vars.css';
    position: relative;
    width: 100%;
    height: 44px;
    flex-shrink: 0;
    background: var(--location-select-background, var(--col--light));
}

.locationSelect {
    color: var(--location-select-color, var(--col--light));
    background: var(--location-select-background, var(--col--dark));
    width: 100%;
}

.locationSelectContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
}

.locationSelectInfo {
    font-family: var(--ff--pharagraph);
    font-style: normal;
    font-weight: var(--info-font-weight);
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: var(--col--gray4);
}

.locationSelectIcon {
    font-size: 10px;
    margin-left: 6.1px;
    margin-right: 4.4px;
    color: var(--col--light);
}

.locationSelectChevronIcon::before {
    margin-left: 5px;
    color: var(--col--light);
}

.locationSelectLocationBlock {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}

.unavailableBlockControls {
    margin-top: var(--bagContent-unavailableBlockControls-margin-top);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.headerSmall {
    font-size: 20px;
    line-height: 22px;
}

.textSmall {
    font-size: 16px;
    line-height: 20px;
}

.iconsSmall {
    width: 53px;
    height: 57px;
}

.bagItems .caloriesContainer {
    margin: 30px 0 0;
    padding-top: 16px;
    border-top: 1px solid var(--col--gray4);
}

.bagDealContainer {
    margin-bottom: 30px;
}

.emptyBagItems {
    margin-top: 36px;
}

.addCondimentsBtn {
    display: flex;
    margin-top: 30px;
    font-size: 18px;
    line-height: 20px;
    text-decoration-line: none;
}

.addCondimentsBtn > *:first-child {
    margin-right: 5px;
}

.signInContainer {
    margin-top: 20px;
}

.signInLink {
    margin-top: 15px;
    width: fit-content;
}

.addCondimentsText {
    text-decoration-line: underline;
}

.addCondimentsText:hover {
    text-decoration-line: none;
}

@media (max-width: 768px) {
    .iconsSmall {
        width: 53px;
        height: 57px;
    }

    .buttons {
        box-shadow: none;
    }

    .bagButton:first-child:not(.callToActionButton) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    .bagButton:last-child:not(.callToActionButton) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}

@media (max-width: 959px) {
    .bagDealContainer {
        margin-bottom: 28px;
    }

    div.bagNavigationDropdownContent {
        position: absolute;
        left: 0;
        top: 44px;
        z-index: 1;
        margin: 0;
        max-width: 432px;
        width: 100%;
        background-color: var(--col--light);
    }

    .bagNavigationDropdownModal :global(.marker) {
        position: absolute;
        top: -10px;
        display: block;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid var(--col--dark);
    }

    .closeButton {
        height: 66px;
        font-size: var(--mobile-close-button-font-size, var(--bag-close-label-font-size));
    }
}

@media screen and (min-width: 960px) {
    .modal {
        justify-content: flex-end;
    }

    .content {
        width: 473px;
        align-self: flex-end;
        align-items: center;
    }

    .locationSelectContainer {
        padding: unset;
    }

    .closeButton {
        width: 100%;
    }

    .bagItems {
        width: 370px;
        height: calc(100vh - 380px);
    }

    .emptyBagItems {
        margin-top: 28px;
    }

    .bagItemsList {
        flex-grow: 1;
    }

    .bagItemsEmpty {
        height: calc(100vh - 248px);
        padding: var(--bag-item-empty-padding);
    }

    .marginBottomBagItems {
        height: calc(100vh - 380px - 110px);
        margin-bottom: 110px;
    }

    .header {
        font-size: 32px;
        line-height: 32px;
    }

    .buttons {
        composes: bag from './index.vars.css';
        width: 100%;
        height: var(--buttons-height, 50px);
        margin: var(--buttons-margin);
        padding: var(--buttons-padding);
        box-sizing: border-box;
    }

    .callToActionButtons {
        padding: var(--call-to-action-buttons-padding, 0);
        margin: 0;
        height: var(--call-to-action-buttons-height, auto);
        box-shadow: var(--call-to-action-buttons-box-shadow, none);
        width: 100%;
    }

    .callToActionButtons.buttons > *:first-child {
        margin-right: 0;
        margin-left: 0;
    }

    .callToActionButtonsContainer {
        width: inherit;
    }

    .buttons > *:first-child {
        margin-right: 10px;
    }

    .buttons > *:last-child {
        margin-left: 10px;
    }

    .buttons > .callToActionButton {
        height: var(--call-to-action-button-height, 60px);
        margin: 0;
    }

    .reviewTotal {
        padding: 0;
    }

    .itemCount {
        align-self: center;
        margin-top: 5px;
    }

    .bagEmpty > * {
        margin-left: 52px;
        margin-right: 52px;
    }

    .unavailableNotification {
        padding: 16px 0;
        margin: 0px -32px;
    }

    .locationSelectInfo {
        font-size: 12px;
        line-height: 12px;
    }

    .unavailableHeader {
        font-size: var(--unavailableHeader-desktop-font-size, 26px);
        line-height: var(--unavailableHeader-desktop-line-height, 26px);
    }

    .unavailableHint {
        font-size: var(--unavailableHint-desktop-font-size, 12px);
    }
}
