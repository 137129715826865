.container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.closeButton {
    display: flex;
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
}

.header {
    padding: 50px 20px 0;
    display: flex;
    flex-direction: column;
}

.header .title {
    font-size: 34px;
    line-height: 34px;
    margin: 0;
}

.header .subtitle {
    font-size: 20px;
    line-height: 22px;
    color: var(--col--primary1);
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 42px;
    overflow: hidden;
}

.contentTitle {
    margin: 0;
    padding: 0 20px 16px;
    font-size: 20px;
    line-height: 20px;
}

.condimentsList {
    flex: 1;
    padding: 0 20px 100px;
    overflow-y: auto;
}

.condimentsList > *:last-child {
    margin-bottom: 0;
}

.buttonContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 25px 16px;
    box-sizing: border-box;
    background: linear-gradient(180.84deg, rgba(255, 255, 255, 0) 9.59%, #fff 28.81%);
}

.actionButton {
    width: 100%;
    box-shadow: 0px 3px 6px rgba(24, 52, 77, 0.3);
}

@media (min-width: 960px) {
    .content {
        padding-top: 38px;
    }

    .contentTitle {
        padding: 0 34px 24px;
    }

    .condimentsList {
        padding: 0 34px 100px;
    }

    .buttonContainer {
        padding: 25px 20px;
    }
}
