.input {
    composes: input from './input.vars.css';
    font-family: var(--ff--pharagraph);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: var(--col--dark);
    text-transform: none;
}

.searchInputContainer {
    composes: searchInputContainer from './input.vars.css';
    border: var(--input-border);
    border-radius: var(--input-border-radius);
    display: flex;
    justify-content: space-between;
    background-color: var(--col--light);
}

.searchIcon {
    color: var(--col--dark);
}

.searchInputRightBlock {
    display: flex;
    align-items: center;
    padding-left: 20px;
    width: 100%;
}

.searchInput {
    width: 100%;
}

.searchInput.withIcon {
    margin-left: 10px;
}
