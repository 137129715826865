.loyaltyBanner {
    margin-top: 22px;
    box-shadow: 0px 0px 25px rgb(0 0 0 / 15%);
    border-radius: 2px;
    padding: 20px 22px;
    display: flex;
}

.loyaltyBannerLabelContainer {
    margin-left: 38px;
    display: flex;
    flex-direction: column;
}

.loyaltyBannerLabelHint {
    color: var(--col--utilitySuccess);
    margin-top: 4px;
}
