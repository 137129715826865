.radioButton {
    --radio-button-checked-background: var(--col--primary1);
    --radio-button-checked-center-background: var(--col--light);
    --radioButton-flex-shrink: 0;
    --radioButton-large-mobile-width: 24px;
    --radioButton-large-mobile-height: 24px;
    --radioButton-small-icon-width: 24px;
    --radioButton-small-icon-height: 24px;
    --radioButton-small-icon-mobile-width: 18px;
    --radioButton-small-icon-mobile-height: 18px;
}
