.root {
    background: var(--col--light);
    border: 1px solid var(--col--gray6);
    border-radius: 2px;
}

.fieldset {
    border: none;
}

.input {
    font-family: var(--ff--pharagraph);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-transform: none;
    padding: 15px 0 11px;
    color: var(--col--dark);
    height: 50px;
    box-sizing: border-box;
}

.input::placeholder {
    color: var(--col--gray6);
    opacity: 1;
}

.endAdornment {
    padding-right: 0;
    padding-left: 19px;
}

.error {
    border-color: var(--col--utilityError);
}

.disabled {
    background-color: var(--col--gray1);
    border-color: var(--col--gray4);
    color: var(--col--gray5);
}

.helperError:global(.MuiFormHelperText-root.Mui-error) {
    color: var(--col--utilityError);
    margin: 8px 0 0;
    font-family: var(--ff--pharagraph-strong);
    font-weight: 657;
    line-height: 14px;
}

.helperText {
    font-family: var(--ff--pharagraph);
    font-weight: 485;
    font-size: 12px;
    line-height: 16px;
    margin: 15px 0 0;
    color: var(--col--gray6);
}
