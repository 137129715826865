.list {
    composes: list from './index.vars.css';
    padding-inline-start: 0;
    margin-bottom: 0;
    margin-top: 0;
}

.listWithItems {
    margin-top: var(--list-margin-top);
}

.subList {
    width: 100%;
    margin-top: 0px;
}

.list > li {
    font-family: var(--ff--pharagraph);
    color: var(--col--gray6);
    font-size: 14px;
    line-height: 18px;
}

.list > li::before {
    color: var(--col--gray6);
}

.hiddenList {
    margin-top: 0;
}

.button {
    composes: list from './index.vars.css';
    display: flex;
    font-family: var(--button-font-family);
    font-size: var(--button-font-size);
    line-height: var(--button-line-height);
    text-transform: var(--button-text-transform, uppercase);
    text-decoration-line: underline;
    align-items: center;
    font-weight: var(--button-font-weight);
    cursor: pointer;
    color: var(--button-color);
    margin-top: var(--button-margin-top);
    margin-bottom: var(--button-margin-bottom);
}

.collapsedButton {
    display: none;
}

.modifierItem {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.modifierItemInfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.modifierItem:not(.wingTypeModifierItem) span {
    font-family: var(--list-font-family);
    color: var(--col--gray6);
    font-size: var(--list-font-size);
    line-height: var(--list-line-height);
    font-weight: var(--list-font-weight);
}

.wingTypeModifierItem::before {
    content: '';
}

.wingTypeModifierItem {
    padding: 2px 0;
}

.wingTypeModifierItem span {
    color: var(--col--gray6);
}

.unavailableModifier {
    font-family: var(--list-font-family);
    font-size: var(--list-font-size);
    font-weight: var(--list-font-weight);
    margin-left: 10px;
    color: var(--col--utilityError);
}

.unavailableModifierItem {
    font-family: var(--list-font-family);
    font-size: var(--list-font-size);
    font-weight: var(--list-font-weight);
    color: var(--col--gray6);
    text-decoration: line-through;
    opacity: var(--unavailableModifierItem-opacity, 0.5);
}

.simpleModifierItem span {
    font-family: var(--list-font-family);
    font-size: var(--list-font-size);
    font-weight: var(--list-font-weight);
}

.unavailableListModifierItem {
    padding: var(--unavailableListModifierItem-padding-left, 14px);
}

.unavailableListModifierItem::before {
    content: var(--unavailableListModifierItem-before-content, '\2022');
}
