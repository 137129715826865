.suggestProductCardContainer {
    --radioButton-checkbox-mobile-top: 10px;
    --radioButton-checkbox-mobile-right: 10px;
    --temporarilyUnavailable-font-weight: 800;
    --temporarilyUnavailable-font-size: 11px;
    --temporarilyUnavailable-line-height: 15px;
    --temporarilyUnavailable-text-transform: uppercase;
    --temporarilyUnavailable-border-bottom-left-radius: 0;
    --temporarilyUnavailable-border-bottom-right-radius: 0;
    --suggestProductCardContainer-justify-content: unset;
    --suggestProductCardContainer-margin: 0 20px 20px 0;
}
