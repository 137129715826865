.dealItemContainer {
    --deal-item-container-border: none;
    --deal-item-container-border-radius: initial;
    --deal-item-container-padding: 10px 10px 20px;
    --deal-item-container-height: 100px;
    --deal-item-container-min-width-mobile: 328px;
    --deal-item-container-min-width-desktop: 328px;
    --deal-header-line-height: 18px;
    --deal-header-white-space: nowrap;
    --deal-header-overflow: hidden;
    --deal-buttons-block-margin-top: 0;
    --deal-view-deal-button-font-size: 11px;
    --deal-view-deal-button-font-weight: 700;
    --deal-view-deal-button-text-transform: uppercase;
    --divider-margin: 0 21px;
}
