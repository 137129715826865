.container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1;
}

.title {
    font-size: 20px;
    line-height: 22px;
    margin: 40px 0 15px;
}

.content {
    display: flex;
    width: calc(100% + 0.5px);
    padding: 0;
    overflow: hidden;
}

@media (max-width: 960px) {
    .content {
        width: 100%;
    }
}
