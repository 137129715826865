.list {
    --list-font-family: var(--base-font);
    --list-font-size: 14px;
    --list-line-height: 18px;
    --list-font-weight: 400;
    --list-margin-top: 0px;
    --button-font-family: var(--ff--pharagraph);
    --button-margin-top: 10px;
    --button-margin-bottom: 10px;
    --button-font-weight: 800;
    --button-font-size: 11px;
    --button-line-height: 11px;
    --button-color: var(--col--gray6);
}
