.modal {
    composes: modal from 'suggestProductModal.vars.css';
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container {
    background: var(--col--light);
    max-height: 100%;
    position: relative;
    outline: none;
    margin: 8vh 123px;
    overflow: hidden;
    border-radius: var(--container-border-radius);
}

.container::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: var(--container-after-height);
    width: 100%;
    background: var(--col--primary1);
}

.containerContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: stretch;
}

.header {
    background: auto;
    color: var(--col--dark);
    padding: 24px 24px 0 40px;
}

.headerTop {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: var(--header-margin-bottom);
}

.modalCloseBtn {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.modalCloseBtnText {
    margin-right: 15px;
    text-decoration: underline;
}

.containerContent .title {
    font-family: var(--ff--header-small);
    font-weight: var(--title-font-weight);
}

.containerContent .subtitle {
    margin: 0;
    color: var(--col--primary1);
    font-family: var(--subtitle-font-family);
    font-size: var(--subtitle-font-size);
    line-height: var(--subtitle-line-height);
    font-weight: var(--subtitle-font-weight);
}

.titleWrapper {
    display: flex;
    align-items: flex-end;
}

.hintText {
    margin-left: 15px;
}

.content {
    overflow-y: auto;
    padding: var(--content-padding);
}

.contentContainer {
    display: flex;
    flex-wrap: wrap;
    gap: var(--contentContainer-gap);
}

.footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: var(--footer-padding);
    background: auto;
}

.footerButtons {
    display: flex;
    margin-left: auto;
}

.footerButton {
    margin-right: 20px;
}

.footerButton:last-child {
    margin-right: 0;
}

.footerIcon {
    position: absolute;
    bottom: 0px;
    font-size: 75px;
}

.footerIcon::before {
    color: var(--col--primary1);
}

/* decreased padding on 20px to fit 5 cards in one line with scrollbar */
@media (min-width: 1440px) {
    .content {
        padding-right: var(--content-large-padding-right);
    }
}

@media (max-width: 767px) {
    .container {
        width: 100%;
        height: 100%;
        margin: auto;
        overflow: auto;
        border-radius: 0;
    }

    .container::after {
        display: none;
    }

    .header {
        padding: 20px 20px 10px;
    }

    .headerTop {
        margin-bottom: var(--headerTop-mobile-margin-bottom);
    }

    .containerContent .subtitle {
        font-size: var(--subtitle-mobile-font-size);
        line-height: var(--subtitle-mobile-line-height);
    }

    .containerContent .title {
        font-size: var(--title-mobile-font-size);
    }

    .titleWrapper {
        justify-content: space-between;
    }

    .modalCloseBtnText {
        margin-right: 13px;
    }

    .closeIcon {
        font-size: 18px;
    }

    .hintText {
        margin-left: 10px;
    }

    .content {
        flex-grow: 1;
        padding: var(--content-mobile-padding);
    }

    .contentContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, 158px);
        gap: 20px 19px;
    }

    .footer {
        padding: var(--footer-mobile-padding);
    }

    .footerIcon {
        display: none;
    }

    .footerButtons {
        flex-grow: 1;
        justify-content: flex-end;
    }

    .footerButton {
        flex: 1;
        height: var(--footerButton-mobile-height);
        margin: 0;
    }
}
