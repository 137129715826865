.wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 14px 20px 0px;
}

.wrapper .label {
    color: var(--col--dark);
    font-size: 12px;
    line-height: 12px;
}

.label::after {
    content: '*';
    color: var(--col--utilityError);
    padding-left: 3px;
}

.buttonLabel {
    text-transform: none;
}

div.list {
    max-height: 258px;
}

div.selectedItem {
    font-family: var(--ff--pharagraph-strong);
}

.deliveryTimesErrors {
    color: var(--col--utilityError);
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    margin-left: 20px;
    margin-right: 20px;
}

.invalidTimeErrorContainer {
    background-color: var(--col--gray1);
    padding: 16px;
    text-align: center;
    margin: 14px 20px 0px;
}

.invalidTimeErrorLabel {
    color: var(--col--secondary6);
}
