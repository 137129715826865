.container {
    composes: container from './bagItemSuggestedDeal.vars.css';
    background: var(--col--gray1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--container-padding);
    border: 1px solid var(--col--gray4);
    text-align: center;
    font-family: var(--ff--pharagraph);
    font-weight: var(--container-font-weight);
    border-bottom-left-radius: var(--container-border-bottom-left-radius);
    border-bottom-right-radius: var(--container-border-bottom-right-radius);
}

.offerName {
    color: var(--offerName-color);
    font-size: 16px;
    line-height: 26px;
}

.selectProductsContainer {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    border: var(--selectProductsContainer-border);
    width: 100%;
    box-sizing: border-box;
    padding: 13px 10px 11px;
    color: var(--selectProductsContainer-color);
    cursor: pointer;
}

.selectProducts {
    margin-top: 8px;
    font-size: 14px;
    line-height: 18px;
}

@media screen {
    .offerName {
        font-size: 14px;
        line-height: var(--offerName-line-height);
    }
}

@media (max-width: 768px) {
    .container {
        padding: var(--container-mobile-padding);
    }
}
