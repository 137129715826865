.bagItemContainer,
.productDealContainer {
    box-shadow: var(--bagItem_container-box-shadow, 0px 0px 25px rgba(0, 0, 0, 0.15));
    margin-bottom: var(--bagItemContainer-margin);
    position: relative;
}

.productDealContainer,
.suggestedDealContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 0;
}

.productDealContainer {
    border: var(--bagItem_container-border, none);
    border-radius: var(--productDealContainer-border-radius, 0);
    padding: var(--productDealContainer-padding);
    min-height: var(--productDealContainer-min-height);
    align-items: var(--productDealContainer-align-items);
}

.suggestedDealContainer {
    border: var(--suggestedDealContainer-border);
    background: var(--col--gray1);
    margin-bottom: 30px;
    box-shadow: none;
    padding: var(--suggestedDealContainer-padding);
    border-radius: var(--suggestedDealContainer-border-radius);
}

.dealWarning {
    font-family: var(--ff--pharagraph);
    font-size: var(--deal-warning-font-size);
    font-weight: 400;
    line-height: var(--deal-warning-line-height);
    color: var(--col--primary1);
    margin-top: var(--deal-warning-margin-top);
}

.offerButtonSection {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: var(--offer-button-section-align-items);
}

.offerButton:nth-child(2) {
    margin-top: 15px;
}

.offerButtonRedeem {
    color: var(--col--primary1);
}

.removedItemProductName {
    font-size: var(--removedItemProductName-font-size);
    line-height: var(--removedItemProductName-line-height);
}

.bagItemLabel {
    font-size: var(--bag-bagItemLabel-font-size);
    text-transform: var(--bag-bagItemLabel-text-transform);
    line-height: var(--bag-bagItemLabel-line-height);
}

.tooltipLabel {
    font-size: 14px;
    text-transform: none;
    line-height: 18px;
    top: -20px;
    height: 42px;
    border-radius: 2px;
}

.verticalDivider {
    width: 0px;
    height: 23px;
    border-left: 1px solid var(--col--gray4);
    margin: 0 21px;
}

.bagItemWithSelectDealContainer {
    margin-bottom: 0;
}

.selectDealAction {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 13px;
    color: var(--col--gray6);
    cursor: pointer;
    border: 2px dashed var(--col--gray4);
}

.bagItemBlockContainer {
    border: var(--bagItem_container-border, none);
    border-radius: var(--bagItem_container-border-radius, 0);
}

.bagItemBlockContainer:not(:only-child) {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.bagItemSimpleOffer .bagItemBlockContainer {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.bagItem {
    padding: var(--bagItem-padding);
    display: flex;
}

.bagItemImage {
    height: var(--bag-item-image-height, 60px);
}

.productCalories,
.productSize,
.discountName {
    font-size: 12px;
    line-height: 16px;
}

.unavailable .bagItemImage,
.unavailable .productDealContainer {
    opacity: 0.5;
}

.dealInfoContainer {
    display: flex;
    flex-direction: column;
}

.offerHeader {
    line-height: var(--offerDataHeader-line-height);
    margin-bottom: var(--offerDateHeader-margin-bottom);
    max-width: var(--offerDateHeader-max-width);
}

.offerDateHeader {
    color: var(--offerDateHeader-text-color);
    font-size: 12px;
    line-height: var(--offerDataHeader-line-height);
    font-family: var(--offerDateHeader-font-family);
    font-weight: var(--offerDateHeader-font-weight);
}

.bagItemInfo {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-left: var(--bag-item-info-margin-left);
    padding-top: var(--bag-item-info-padding-top, 0);
    width: calc(100% - 92px);
}

.bagItemModifiers {
    margin-bottom: 10px;
}

.bagItemHeader,
.bagItemModifier {
    display: flex;
    justify-content: space-between;
}

.bagItemLinks > button {
    padding: var(--bagItemLinks-padding);
}

.bagItemLinks > *:first-child {
    padding-left: 0;
}

.bagItemLinks > :nth-child(2n + 1) {
    border-right: 1px solid var(--col--gray4);
}

.bagItemLinks > :nth-child(2n) {
    border-left: 1px solid var(--col--gray4);
}

.bagItemLinks.noBorder button {
    border-right: none;
    border-left: none;
}

.quantityControls {
    display: flex;
    height: 45px;
    position: relative;
}

.quantityControl {
    flex-grow: 1;
    border-top: 1px solid var(--col--gray4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.quantityControl:nth-child(1),
.quantityControl:nth-child(3) {
    color: var(--col--gray4);
}

.quantityControl:nth-child(2) {
    flex: 2;
}

.quantityControl:not(:last-child) {
    border-right: 1px solid var(--col--gray4);
}

.quantityControl.activeControl {
    color: var(--col--primary1);
}

.quantityControl.activeControl:hover {
    cursor: pointer;
}

.removedItemContainer {
    composes: removeItemContainer from './index.vars.css';
    border: 1px solid var(--col--gray4);
    border-radius: var(--removedItemContainer-border-radius, 0);
    background-color: var(--col--gray1);
}

.removedItemProduct,
.removedItemAction {
    display: flex;
    justify-content: center;
    align-items: center;
}

.removedItemProductWrapper {
    text-align: center;
}

.removedItemProduct {
    margin: var(--removedItemProduct-margin, 0);
    padding: var(--removedItemProduct-padding, 0 12px);
    height: var(--removedItemProduct-height, 80px);
    border-bottom: 1px solid var(--col--gray4);
}

.removedItemAction {
    height: var(--removedItemAction-height, 45px);
    cursor: pointer;
}

.removedItemActionLine {
    display: var(--removedItemActionLine-display);
}

.removedItemActionLine > *:first-child {
    margin-right: 5px;
}

.product {
    font-family: var(--ff--header);
    font-size: var(--bag-bagItemLabel-addons-font-size);
}

.unavailable .offerHeader,
.unavailable .offerButton,
.unavailable .offerDateHeader {
    color: var(--col--gray5);
    user-select: none;
    pointer-events: none;
}

.unavailableItem .bagItemImage {
    opacity: 0.5;
}

.unavailableItem .bagItemLabel {
    color: var(--col--gray6);
}

.unavailableItem .bagItemLinks .bagItemLabel {
    color: var(--col--dark);
}

.imgColumn {
    width: 88px;
    height: 100%;
    display: flex;
    overflow: hidden;
    background: var(--col--primary1);
}

.dealImage {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: fill !important;
}

.quantityControl:focus:not(:focus-visible),
.bagItemLabel:focus:not(:focus-visible),
.removedItemActionLine:focus:not(:focus-visible) {
    outline: none;
}

.discountName {
    color: var(--col--primary1);
}

.bagNotAvailableLabel {
    font-size: 12px;
    line-height: 12px;
    color: var(--col--utilityError);
    margin-left: 10px;
}

.bagUnavailableItemLabel {
    font-size: var(--bag-bagItemLabel-addons-font-size);
    text-decoration: line-through;
}

.unavailableChildItem {
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {
    .suggestedDealContainer {
        border: 1px solid var(--col--gray4);
    }
}
