.condimentWrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    background-color: var(--col--light);
    border-radius: 6px;
    border: 1px solid var(--col--gray3);
    min-height: 42px;
    width: 405px;
    padding: 8px 16px 8px 1px;
    margin-bottom: 15px;
    box-sizing: border-box;
}

.textBlock {
    display: flex;
    flex-direction: column;
    margin-left: 9px;
    flex-grow: 1;
}

.controller {
    min-width: 98px;
    padding: 10px 0;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.quantity {
    position: relative;
    top: 2px;
}

.icons {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: var(--col--primary1);
    border: 1px solid var(--col--primary1);
    cursor: pointer;
}

.disabled {
    border-color: var(--col--gray4);
    color: var(--col--gray4);
}

.tooltip {
    font-size: 14px;
    padding: 12px 16px;
}

.image {
    width: 60px;
    height: 44px;
}

.drinkCarrier {
    font-size: 16px;
    line-height: 16px;
}

@media (max-width: 1360px) {
    .condimentWrapper {
        width: 100%;
    }
}
