.checkbox {
    composes: checkbox from './checkbox.vars.css';
    box-sizing: border-box;
    background-color: var(--col--light);
    border: 1px solid var(--checkbox-border-color);
    flex-shrink: var(--checkbox-flex-shrink);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--checkbox-border-radius);
}

.checkbox.small {
    width: 24px;
    height: 24px;
}

.checkbox.standard {
    width: 24px;
    height: 24px;
}

.checkbox.large {
    width: 32px;
    height: 32px;
}

.checkbox:hover {
    cursor: pointer;
}

.checkboxSelected {
    background-color: var(--checkbox-checked-background-color);
    border: 1px solid var(--checkbox-checked-background-color);
}

.checkboxDisabled {
    opacity: 0.5;
}

.checkIcon {
    display: none;
    color: var(--checkbox-icon-color);
    width: 100%;
    height: 100%;
}

.checkboxSelected .checkIcon {
    display: block;
}

@media (max-width: 767px) {
    .checkbox.small {
        width: 18px;
        height: 18px;
    }

    .checkbox.large {
        width: var(--checkbox-large-mobile-width);
        height: var(--checkbox-large-mobile-height);
    }
}
