.container {
    composes: container from './notFound.vars.css';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--notFound-container-margin-bottom);
}

.header {
    margin: var(--header-margin);
    color: var(--header-color);
    text-align: center;
}

.text {
    text-align: center;
}

.icons {
    position: relative;
    width: 76px;
    height: 76px;
}

.iconsWithHeading {
    margin-bottom: 21px;
}

.icon.iconBan::before {
    font-size: 76px;
    color: var(--col--dark);
    position: absolute;
    top: 0;
    left: 0;

    /* z-index of material ui modal + 1 with to display on top of second icon */
    z-index: 1301;
}

.icon.iconCow::before {
    font-size: 50px;
    color: var(--col--primary1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* z-index of material ui modal */
    z-index: 1300;
}

@media (max-width: 768px) {
    .container {
        padding: 0 20px;
    }

    .icons {
        position: relative;
        width: 60px;
        height: 60px;
    }

    .icon.iconBan::before {
        font-size: 60px;
    }

    .icon.iconCow::before {
        font-size: 40px;
    }

    .icon.iconBan {
        font-size: 60px;
        color: var(--col--dark);
        position: absolute;
        top: 0;
        left: 0;
    }

    .icon.iconCow {
        font-size: 40px;
        color: var(--col--primary1);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
