.verticalProductCard {
    composes: verticalProductCard from './verticalProductCard.vars.css';
    display: flex;
    position: relative;
    flex-direction: column;
    box-sizing: border-box;
    width: var(--card-desktop-width);
    min-width: var(--card-desktop-width);
    height: auto;
    min-height: var(--card-desktop-min-height);
    margin: var(--card-desktop-margin);
    background: var(--card-background);
    box-shadow: var(--card-box-shadow);
    border-radius: var(--card-border-radius);
    border: var(--card-border);
    align-self: var(--card-align-self);
}

.badgeVerticalProductCard {
    border-radius: var(--badgeVerticalProductCard-border-radius);
}

.verticalProductCard > a {
    flex-grow: 1;
    overflow: hidden;
}

.sodiumWarningIcon {
    margin-left: 5px;
    max-height: 18px;
    max-width: fit-content;
}

.itemImage {
    padding: var(--image-desktop-padding);
    width: 100%;
    min-height: var(--image-desktop-min-height);
    box-sizing: border-box;
    align-self: center;
}

.itemDescription {
    padding: 0 10px;
    height: var(--card-item-description-height);
}

.itemName {
    text-align: center;
    margin: var(--product-name-margin);
    padding-top: var(--product-name-padding-top, initial);
    font-size: var(--itemName-font-size);
    line-height: var(--itemName-line-height);
    text-transform: var(--itemName-text-transform);
}

.caloriesAndPrice {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
}

.successErrorModal .successErrorModalButtonRow {
    bottom: 0px;
}

.successErrorModalButton {
    width: 100%;
}

.tempUnavailable {
    color: var(--col--utilityError);
    width: 100%;
    text-align: center;
    margin-top: -3px;
}

@media (max-width: 960px) {
    div.successErrorModal {
        height: 318px;
    }
}

@media (max-width: 767px) {
    .verticalProductCardSideScroll {
        margin-right: 10px;
    }

    .verticalProductCardSideScroll:last-of-type {
        margin-right: 0;
    }

    .verticalProductCard {
        margin: var(--card-mobile-margin);
    }

    .itemDescription {
        height: var(--card-item-description-mobile-height);
    }

    .sodiumWarningIcon {
        max-height: 14px;
    }

    .tempUnavailable {
        margin: 0;
    }
}

@media (max-width: 767px) and (min-width: 375px) {
    .verticalProductCard {
        width: var(--card-mobile-width);
        min-width: var(--card-mobile-width);
        min-height: var(--card-mobile-min-height);
        height: auto;
    }

    .itemImage {
        padding: var(--image-mobile-padding);
        min-height: var(--image-mobile-min-height);
    }
}

@media (max-width: 374px) {
    .verticalProductCard {
        width: 145px;
        min-width: var(--card-less-mobile-width);
        min-height: 240px;
        height: auto;
    }

    .verticalProductCardSideScroll {
        width: var(--card-mobile-width);
        min-width: var(--card-mobile-width);
        height: var(--card-mobile-height);
    }

    .itemImage {
        padding: var(--image-mobile-padding);
        min-height: var(--image-mobile-min-height);
    }
}
