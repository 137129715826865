.suggestProductCardContainer {
    composes: suggestProductCardContainer from './suggestProductCardContainer.vars.css';
    cursor: pointer;
    justify-content: var(--suggestProductCardContainer-justify-content);
}

.suggestProductCardContainer.container {
    margin: var(--suggestProductCardContainer-margin);
}

.radioButton,
.checkbox {
    position: absolute;
    top: 10px;
    right: 10px;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.temporarilyUnavailable {
    background: var(--col--gray1);
    border-top: 1px solid var(--col--gray4);
    font-family: var(--ff--header);
    height: 44px;
    font-weight: var(--temporarilyUnavailable-font-weight);
    font-size: var(--temporarilyUnavailable-font-size);
    line-height: var(--temporarilyUnavailable-line-height);
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: var(--temporarilyUnavailable-text-transform);
    border-bottom-left-radius: var(--temporarilyUnavailable-border-bottom-left-radius);
    border-bottom-right-radius: var(--temporarilyUnavailable-border-bottom-right-radius);
}

@media (max-width: 768px) {
    .suggestProductCardContainer {
        margin: 0;
        width: 158px;
        min-height: 200px;
    }

    .calories {
        margin-bottom: 17px;
    }

    .temporarilyUnavailable {
        font-size: 10px;
    }

    .radioButton,
    .checkbox {
        top: var(--radioButton-checkbox-mobile-top);
        right: var(--radioButton-checkbox-mobile-right);
    }
}
