.dealsContainer {
    --deals-container-margin-desktop: 10px 0;
    --deals-heading-margin-desktop: 0 0 24px;
    --deals-carousel-container-box-shadow: 0 0 30px rgba(0, 0, 0, 0.15), inset 0 0 70px rgba(0, 0, 0, 0.15);
    --deals-bottom-message-margin-top: 7px;
    --deals-bottom-message-font-size-desktop: 14px;
    --deals-bottom-message-font-size-mobile: 12px;
    --deals-bottom-message-line-height: 18px;
    --deals-bottom-arrow-width: 32px;
    --deals-bottom-arrow-height: 32px;
    --deals-bottom-arrow-top: 28px;
    --deals-bottom-arrow-background: var(--col--primary1);
    --deals-bottom-arrow-svg-font-size: 2.5rem;
    --deals-bottom-right-arrow-right: -16px;
    --deals-bottom-left-arrow-left: -16px;
    --view-all-link-text-transform: uppercase;
}
