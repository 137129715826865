.dealsContainer {
    composes: dealsContainer from './userDeals.var.css';
    margin: 15px 0 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.carouselContainer {
    box-shadow: var(--deals-carousel-container-box-shadow);
}

.dealsHeading {
    margin: 0 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.dealsHeading h4 {
    margin: 0;
}

button.rightArrow,
button.leftArrow {
    width: var(--deals-bottom-arrow-width);
    height: var(--deals-bottom-arrow-height);
    top: var(--deals-bottom-arrow-top);
    background: var(--deals-bottom-arrow-background);
    font-size: 1.5rem !important;
}

button.rightArrow svg,
button.leftArrow svg {
    font-size: var(--deals-bottom-arrow-svg-font-size);
}

button.rightArrow {
    right: var(--deals-bottom-right-arrow-right);
}

button.leftArrow {
    left: var(--deals-bottom-left-arrow-left);
}

.dealsHeader {
    margin-top: 5px;
    margin-bottom: 10px;
}

.unavailableDeals {
    opacity: 0.5;
}

.bottomMessage {
    margin-top: var(--deals-bottom-message-margin-top);
    font-size: var(--deals-bottom-message-font-size-mobile);
    line-height: var(--deals-bottom-message-line-height);
}

.viewAllLink {
    text-transform: var(--view-all-link-text-transform);
}

@media screen and (min-width: 960px) {
    .dealsContainer {
        composes: dealsContainer from './userDeals.var.css';
        margin: var(--deals-container-margin-desktop);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .dealsHeader {
        font-size: 20px;
    }

    .dealsHeading {
        margin: var(--deals-heading-margin-desktop);
    }

    .bottomMessage {
        font-size: var(--deals-bottom-message-font-size-desktop);
    }
}
