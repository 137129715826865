.loaderContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: var(--col--light);
    z-index: 1000;
}

.loader {
    height: 120px;
}

.loader svg {
    width: 120px;
}

.content {
    padding: 20px 20px 30px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
}

.content .subheading {
    font-size: 20px;
    line-height: 22px;
    color: var(--col--primary1);
}

.content .heading {
    margin: 0;
    font-size: 30px;
    line-height: 30px;
    text-align: center;
}
