.container {
    --container-border-top-left-radius: 0;
    --container-border-top-right-radius: 0;
    --container-height: 27px;
    --container-padding: 5px 26px;
    --container-mobile-padding: 5px 10px;
    --container-mobile-font-size: 12px;
    --container-mobile-line-height: 16px;
    --dealName-color: var(--col--gray6);
}
