.radioButton {
    composes: radioButton from './radioButton.vars.css';
    padding: 0;
    background: var(--col--light);
    flex-shrink: var(--radioButton-flex-shrink);
}

.radioButton:hover {
    background: transparent !important;
}

.radioButton input:focus .icon {
    outline: initial;
}

.radioButton.small .icon {
    width: var(--radioButton-small-icon-width);
    height: var(--radioButton-small-icon-height);
}

.radioButton.standard .icon {
    width: 24px;
    height: 24px;
}

.radioButton.large .icon {
    width: 32px;
    height: 32px;
}

.icon {
    box-sizing: border-box;
    border-radius: 50%;
    border: 1px solid var(--col--gray6);
    background-color: transparent;
}

.checkedIcon {
    background-color: var(--radio-button-checked-background);
    border-color: var(--radio-button-checked-background);
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkedIcon::before {
    content: '';
    display: block;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    background: var(--radio-button-checked-center-background);
}

@media (max-width: 767px) {
    .radioButton.large .icon {
        width: var(--radioButton-large-mobile-width);
        height: var(--radioButton-large-mobile-height);
    }

    .radioButton.small .icon {
        width: var(--radioButton-small-icon-mobile-width);
        height: var(--radioButton-small-icon-mobile-height);
    }
}
