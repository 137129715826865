.locationFindWrapper {
    composes: pickupLocationFind from './pickupLocationFind.vars.css';
    background-color: var(--col--dark);
    padding: var(--location-find-padding);
}

.searchLeftIcon {
    color: var(--location-find-search-left-icon);
}

.searchButton {
    width: 100%;
    height: 50px;
    text-transform: uppercase;
}

.searchSeparator {
    display: flex;
    align-items: center;
    margin: 13px 0;
    color: var(--col--gray5);
}

.separator {
    color: var(--col--gray5);
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    font-family: var(--ff--pharagraph);
    margin-top: 18px;
    margin-bottom: 16px;
    text-align: center;
    display: flex;
    align-items: center;
}

.separator span {
    margin: 0 8px;
}

.separator::before,
.separator::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--col--gray6);
}
