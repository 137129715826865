.modal {
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    outline: none;
    position: relative;
    width: 530px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--col--light);
    box-sizing: border-box;
}

.closeButton {
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
}

.closeIcon {
    color: var(--col--dark);
}

.warnIcon {
    color: var(--col--primary1);
    margin-top: 43px;
    margin-bottom: 30px;
}

.title {
    margin-bottom: 10px;
}

.description {
    padding: 0 30px;
    text-align: center;
}

.dealName {
    font-weight: 800;
}

.buttons {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 30px;
    justify-content: center;
    width: 100%;
}

.button {
    padding: 0 24px;
}

.button:first-child {
    margin-right: 20px;
}

@media (max-width: 960px) {
    .content {
        overflow-y: visible;
        width: 100%;
        height: 100%;
    }

    .closeButton {
        right: 24px;
        top: 24px;
    }

    .description {
        padding: 0 22px;
    }

    .buttons {
        bottom: 0;
    }

    .button {
        width: 100%;
    }

    .button:first-child {
        margin-right: 1px;
    }
}
