.modal {
    composes: navigationDropdown from './navigationDropdown.vars.css';
    top: calc(100% + 22px);
    right: 0;
}

.content {
    background-color: var(--content-background-color);
    margin: 0 -20px;
    outline: none;
    box-shadow: var(--content-box-shadow);
    border-radius: var(--content-border-radius, 0px);
}

.isFullScreen {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--col--light);
    margin: 0;
}

@media (min-width: 960px) {
    .modal {
        margin: auto;
    }

    .content {
        margin: 0;
        padding: 20px;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        animation: slide-in 0.1s linear;
    }

    .marker {
        position: absolute;
        top: -10px;
        display: block;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid var(--col--dark);
    }
}

@keyframes slide-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
