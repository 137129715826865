.locationSelect {
    --info-font-weight: 700;
    --info-color: var(--col--gray5);
    --icon-color: var(--col--light);
    --name-text-font-size: 16px;
    --name-text-line-height: 18px;
    --name-text-font-weight: 800;
    --name-font-family: var(--ff--subheader-small);
    --chevron-icon-color: var(--col--light);
}
