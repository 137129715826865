.methodTabWrapper {
    composes: locationMethodTab from './locationMethodTab.vars.css';
}

.detailsContainer {
    margin: 20px 20px 0;
    background-color: var(--details-container-background-color);
    text-align: center;
    padding: 0 30px;
    box-shadow: var(--details-container-box-shadow);
    border: var(--details-container-border);
    border-radius: var(--details-container-border-radius);
    box-sizing: border-box;
    z-index: 900;
}

.startOrderCtaWrapper {
    padding: 20px;
}

.backgroundLine {
    background-color: var(--background-line-background-color);
    width: 100%;
    height: var(--background-line-height, 110px);
    margin-bottom: var(--background-line-margin-bottom, -110px);
}

.detailsContainer .title {
    color: var(--title-color);
}

.title {
    font-family: var(--ff--subheader-small);
    padding-top: 17px;
    color: var(--title-color);
}

.displayName {
    line-height: var(--display-name-line-height, 22px);
    font-size: var(--display-name-font-size, 18px);
}

.withLink {
    text-decoration-line: underline;
}

.address {
    margin: 5px 0 1px;
    color: var(--col--dark);
}

.phoneLink {
    margin-bottom: var(--phone-link-margin-bottom, 8px);
}

.changeLocationButton {
    margin: 8px 0 17px;
    color: var(--col--dark);
}

.changeLocationButtonDelivery {
    margin: 14px 0 17px;
}

.statusText {
    color: var(--col--gray6);
    display: flex;
    align-items: center;
    justify-content: center;
}

.statusText.highlight {
    color: var(--status-highlight-color, var(--col--gray6));
}

.loader {
    display: flex;
    height: 14px;
    justify-content: center;
    color: var(--col--dark);
}

@media screen and (max-width: 959px) {
    .title {
        text-transform: var(--mobile-title-text-transform);
    }

    .withLink {
        font-size: var(--mobile-withLink-font-size);
        line-height: var(--mobile-withLink-line-height, 22px);
    }

    .changeLocationButton {
        font-size: var(--mobile-changeLocationButton-font-size);
        line-height: var(--mobile-changeLocationButton-line-height);
        font-weight: var(--mobile-changeLocationButton-font-weight);
    }

    .backgroundLine {
        height: var(--mobile-background-line-height, 110px);
        margin-bottom: var(--mobile-background-line-margin-bottom, -110px);
    }

    .detailsContainer {
        padding: var(--details-container-padding, 0 30px);
    }
}
