.container {
    margin-top: 40px;
    margin-bottom: 5px;
}

.textField {
    width: 100%;
    padding-top: 8px;
    min-height: 88px;
}

.button {
    margin: 3px;
    height: 44px;
    min-height: 44px;
    max-width: 102px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 787;
    border-radius: 0;
}

.success {
    color: var(--col--secondary2);
}

.positionStart {
    margin-right: 10px;
}

@media screen and (max-width: 959px) {
    .container {
        margin-top: 25px;
    }
}
