.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    min-width: 60px;
    position: relative;
}

.bagItemPrice {
    font-size: var(--bag-bagItemLabel-font-size);
}

.bagItemPriceHighlighted {
    color: var(--bag-bagItemPriceHighlighted-color);
}

.unavailableItem .bagItemPrice {
    color: var(--col--gray6);
}

.unavailable .bagItemPrice {
    display: none;
}

.strikethroughPrice {
    position: absolute;
    top: 22px;
    text-decoration: line-through;
    font-weight: 400;
    color: var(--bag-item-strikethroughPrice-color);
    font-size: var(--bag-item-strikethroughPrice-font-size);
    line-height: var(--bag-item-strikethroughPrice-line-height);
    font-family: var(--bag-item-strikethroughPrice-font-family);
}

.price {
    color: var(--col--primary1);
    text-transform: capitalize;
}

.loader {
    justify-content: center;
    align-items: center;
}
