.itemContainer {
    composes: dealItemContainer from './dealItem.var.css';
    display: flex;
    position: relative;
    flex-direction: row;
    min-width: var(--deal-item-container-min-width-desktop);
    height: var(--deal-item-container-height);
    background: var(--col--light);
    margin-right: 10px;
    padding: var(--deal-item-container-padding);
    box-sizing: border-box;
    border: var(--deal-item-container-border);
    border-radius: var(--deal-item-container-border-radius);
}

.itemContainer:last-child {
    margin-right: 0;
}

.imgColumn {
    width: 93px;
    height: 70px;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.dealImage {
    width: 93px;
    height: 70px;
}

.detailsColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15px;
}

.dealHeader {
    line-height: var(--deal-header-line-height);
    margin-bottom: 2px;
    white-space: var(--deal-header-white-space);
    overflow: var(--deal-header-overflow);
    text-overflow: ellipsis;
    max-width: 200px;
}

.expirationText {
    color: var(--col--gray6);
    font-family: var(--base-font);
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.dealsHeading {
    margin-bottom: 15px;
}

.buttonsBlock {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: var(--deal-buttons-block-margin-top);
}

.divider {
    height: 100%;
    width: 1px;
    margin: var(--divider-margin);
    color: var(--col--gray4);
}

.vewDealButton {
    font-size: var(--deal-view-deal-button-font-size);
    font-weight: var(--deal-view-deal-button-font-weight);
    text-transform: var(--deal-view-deal-button-text-transform);
}

@media (max-width: 425px) {
    .itemContainer {
        min-width: var(--deal-item-container-min-width-mobile);
    }

    .button {
        width: 100%;
    }
}
