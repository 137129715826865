.container {
    display: block;
    width: 100%;
}

.locationBlock {
    composes: locationSelect from './index.vars.css';
    padding: 11.4px 0;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    cursor: pointer;
    user-select: none;
}

.locationBlockWrapper.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.name {
    composes: locationSelect from './index.vars.css';
    font-family: var(--name-font-family);
    display: flex;
    align-items: baseline;
}

.name > span.chevronIcon {
    font-size: 10px;
    margin-left: 5px;
}

.locationBlock .icon {
    font-size: 10px;
    margin-left: 6.1px;
    margin-right: 4.4px;
    color: var(--icon-color);
}

.info {
    font-family: var(--ff--pharagraph);
    font-style: normal;
    font-weight: var(--info-font-weight);
    font-size: 10px;
    line-height: 14px;
    text-transform: var(--info-text-transrorm, uppercase);
    color: var(--info-color);
}

.nameText {
    font-size: var(--name-text-font-size);
    line-height: var(--name-text-line-height);
}

.chevronIcon::before {
    color: var(--chevron-icon-color);
}

@media (min-width: 960px) {
    .container {
        width: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    /* using margin-right as a fallback because of issue
    https://stackoverflow.com/questions/33891709/when-flexbox-items-wrap-in-column-mode-container-does-not-grow-its-width
    */
    .locationBlock:not(.mobileOnly) {
        order: 1;
        display: flex;
        border: none;
        flex-basis: auto;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-right: 40px;
        flex-direction: column;
        height: 39px;
    }

    .locationBlock:not(.mobileOnly) .name {
        order: 3;
    }

    .locationBlock:not(.mobileOnly) .nameText {
        font-size: 16px;
        line-height: 18px;
        max-width: var(--name-text-max-width, 100px);
        font-weight: var(--name-text-font-weight);
    }

    .locationBlock:not(.mobileOnly) .info {
        order: 2;
        font-size: 12px;
        line-height: 12px;
        margin-bottom: var(--info-margin-bottom, 6px);
    }

    .locationBlock:not(.mobileOnly) .icon {
        order: 1;
        font-size: 24px;
        margin-top: var(--icon-margin-top, 6px);
        margin-right: 10.35px;
        margin-bottom: var(--icon-margin-bottom, inherit);
        margin-left: 4px;
    }
}

/* TODO this should be addressed during CSS breakpoints fix */
@media (min-width: 1024px) {
    .nameText {
        max-width: 170px;
    }
}

@media (min-width: 1300px) {
    .nameText {
        max-width: 100%;
    }
}

@media (max-width: 767px) {
    .nameText {
        max-width: 215px;
    }
}

@media (max-width: 320px) {
    .nameText {
        max-width: 160px;
    }
}
