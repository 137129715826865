.navigationDropdownContent {
    width: 432px;
    max-width: 100%;
}

div.navigationDropdown {
    padding: 0;
}

@media (max-width: 959px) {
    .navigationDropdownContent {
        width: 100%;
    }
}
