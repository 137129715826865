.bag {
    --header-height: 32px;
    --header-text-transform: capitalize;
    --headerContainer-padding: 26px 0;
    --bagItemContainer-margin: 20px;
    --bagItems-padding: 36px 20px 20px 20px;
    --summaryHeader-margin: 0 0 20px 0;
    --unavailableHint-color: var(--col--primary1);
    --buttons-padding: 0 50px;
    --buttons-height-mobile: 60px;
    --buttons-height: 50px;
    --buttons-box-shadow: none;
    --buttons-width: 100%;
    --buttons-margin: 20px 0;
    --bagItem-padding: 17px 18px 25px 12px;
    --bagItemLinks-padding: 4px 21px;
    --bag-height: calc(100% - 126px);
    --bag-bagItemLabel-font-size: 16px;
    --bag-bagItemLabel-addons-font-size: 11px;
    --bag-bagItemLabel-text-transform: capitalize;
    --bag-bagItemLabel-line-height: 18px;
    --bag-close-label-font-size: 20px;
    --bag-item-info-margin-left: 15px;
    --call-to-action-button-height: 60px;
    --bagContent-unavailableBlockControls-margin-top: 30px;
    --bag-item-strikethroughPrice-color: inherit;
    --bag-item-strikethroughPrice-font-size: inherit;
    --bag-item-strikethroughPrice-line-height: inherit;
    --bag-item-strikethroughPrice-font-family: inherit;
    --offerDataHeader-line-height: 18px;
    --offerDateHeader-text-color: var(--col--gray6);
    --offerDateHeader-line-height: 18px;
    --offerDateHeader-margin-bottom: 2px;
    --offerDateHeader-max-width: 220px;
    --productDealContainer-padding: 13px 15px;
    --deal-warning-font-size: 12px;
    --deal-warning-line-height: 18px;
    --deal-warning-margin-top: 5px;
    --offer-button-section-align-items: normal;
    --suggestedDealContainer-padding: 13px 15px;
    --offerDateHeader-font-family: var(--base-font);
    --offerDateHeader-font-weight: inherit;
    --bag-item-empty-padding: 40px 10px 30px;
    --bag-item-empty-padding-mobile: 40px 20px 20px;
    --bag-item-empty-max-width: 375px;
    --bag-item-empty-box-sizing: border-box;
    --suggestedDealContainer-border-radius: 0;
    --suggestedDealContainer-border: 1px solid var(--col--gray4);
    --bag-bagItemPriceHighlighted-color: var(--col--primary1);
    --productDealContainer-min-height: 82px;
    --productDealContainer-align-items: flex-start;
}

.removeItemContainer {
    --removedItemProduct-padding: 0 12px;
    --removedItemProduct-margin: 0;
    --removedItemProduct-height: 80px;
    --removedItemAction-height: 45px;
    --removedItemContainer-border-radius: 0;
    --removedItemProductName-font-size: 16px;
    --removedItemProductName-line-height: 18px;
    --removedItemActionLine-display: initial;
}

@media (max-width: 960px) {
    .bag {
        --bagItemContainer-margin: 15px;
    }
}
